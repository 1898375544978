import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Text, chakra } from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/query';
import { AccountPaymentsGetContractDetailsResponse, accountApi } from '@netiva/classifieds-api';
import { parseQueryValue } from '@netiva/classifieds-common';
import { DescriptionList, DescriptionListItem, DescriptionListTerm, Loader } from '@netiva/classifieds-ui';

import { ContractDetails, PaymentPanel, PrintContractDetails } from './components';
import { useAuthToken } from '../hooks';
import { AttributeDisplay } from '@/components/ui';
import { DataObjectTypes } from '@/constants';
import { routes } from '@/lib/routes';

const SectionTitle = chakra(Text, {
  baseStyle: {
    fontSize: 'lg',
    fontWeight: 'bold',
    marginBottom: 4,
  },
});

const Section = chakra(Box, {
  baseStyle: {
    marginBottom: 4,
  },
});

export const PaymentOverviewPage: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const contractId = parseQueryValue(params.contractId, parseInt);
  const token = useAuthToken();

  const { data: contractData, isLoading: isLoadingData } = accountApi.useGetPaymentContract({
    contractId,
    token,
  });
  const { data: attributes, isLoading: isLoadingAttributes } = accountApi.useGetAttributes(
    contractData?.dataObject?.categoryId ? { categoryId: contractData.dataObject.categoryId } : skipToken
  );

  const { data: category, isLoading: isLoadingCategories } = accountApi.useGetCategory(
    contractData?.dataObject?.categoryId ? { id: contractData.dataObject.categoryId } : skipToken
  );
  const [createPrintOrder, { isLoading: isCreatingPrintOrder }] = accountApi.useCreatePrintOrder();

  const handleCreatePayment = async (contract: AccountPaymentsGetContractDetailsResponse, provider: string) => {
    let orderId = contract.orderId;
    if (!orderId) {
      const createOrderResponse = await createPrintOrder({
        accountPaymentsCreatePrintOrderRequest: { contractId: contract.contractId, token },
      }).unwrap();
      orderId = createOrderResponse.orderId;
    }
    navigate(routes.paymentProcessing(orderId, provider, token));
  };

  const isPrintAdvert =
    contractData?.dataObject?.type === DataObjectTypes.EInserat ||
    contractData?.dataObject?.type === DataObjectTypes.ChMediaPdf;
  const isLoading = isLoadingData;

  return (
    <>
      <Loader isLoading={isLoading} />
      {contractData && (
        <>
          <Section>
            <SectionTitle>{t('payment.summary')}</SectionTitle>
            <DescriptionList position="relative" maxWidth={500}>
              <Loader isLoading={isLoadingAttributes || isLoadingCategories} />
              {category && (
                <>
                  <DescriptionListTerm>{t('payment.summary.category')}</DescriptionListTerm>
                  <DescriptionListItem>{category.name}</DescriptionListItem>
                </>
              )}
              {attributes?.attributes
                .filter((attribute) => attribute.key !== 'Price' && (!isPrintAdvert || attribute.key !== 'Text'))
                .map((attribute) => {
                  const value = contractData.dataObject?.values.find((v) => v.attributeId == attribute.id);
                  return value && <AttributeDisplay key={attribute.id} attribute={attribute} value={value} />;
                })}
            </DescriptionList>
          </Section>
          <Section>
            <SectionTitle>{t('payment.details', { id: contractId })}</SectionTitle>
            {contractData.printDetails ? (
              <PrintContractDetails contract={contractData} />
            ) : (
              <ContractDetails contract={contractData} />
            )}
            <PaymentPanel
              contract={contractData}
              onCreatePayment={handleCreatePayment}
              isLoading={isCreatingPrintOrder}
            />
          </Section>
        </>
      )}
    </>
  );
};
