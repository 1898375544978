import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';
import {
  accountApi,
  AccountPrintAdvertPrintAdvertRecPrice,
  AccountSharedDataObjectRec,
  createMultipartRequestBody,
} from '@netiva/classifieds-api';
import { readFileAsDataURL } from '@netiva/classifieds-common';

import { routes } from '@/lib/routes';
import { isPrintType } from '@/lib/utils';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdFormData, AdStep, AdValidationStatus } from '@/store/ad/types';

import { CommonStepKeys, InvoicePaymentProvider } from '../constants';
import { useAdAttributes, useAdNavigation, useAdParams, useAdPublishing, useAdSteps, useAdValidator } from '../hooks';
import { getAttributeFormDataValues, getPropertyFormDataValues } from '../utils';
import { getFilterStatus } from '@/pages/MyAds/utils';

export type UploadedFile = {
  fileName: string;
  file: File;
};

export type UploadedFileData = {
  fileName: string;
  dataUrl: string;
};

export type AdContextType = {
  steps: AdStep[];
  validationStatus: AdValidationStatus;
  categoryId?: number;
  formData: AdFormData;
  currentStep?: AdStep;
  currentStepIndex: number;
  currentStepKey?: string;
  gotoNextStep: (skipValidation?: boolean) => void;
  gotoPrevStep: () => void;
  gotoStep: (stepKey: string, skipValidation?: boolean) => void;
  save: () => Promise<void>;
  saveAndPublish: () => Promise<void>;
  isNew: boolean;
  isValid: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  isPrintAdvert: boolean;
  hasChanges: boolean;
  canSave: boolean;
  dataObject?: AccountSharedDataObjectRec;
  uploadedFiles: UploadedFile[];
  setUploadedFiles: Dispatch<SetStateAction<UploadedFile[]>>;
  uploadedFileData: UploadedFileData[];
  price?: AccountPrintAdvertPrintAdvertRecPrice;
};

const noop = () => undefined;
const noopP = () => Promise.resolve();

export const AdContext = createContext<AdContextType>({
  canSave: false,
  currentStepIndex: -1,
  formData: {},
  gotoNextStep: noop,
  gotoPrevStep: noop,
  gotoStep: noop,
  hasChanges: false,
  isLoading: false,
  isNew: true,
  isSubmitting: false,
  isValid: false,
  isPrintAdvert: false,
  save: noopP,
  saveAndPublish: noopP,
  steps: [],
  validationStatus: {},
  uploadedFiles: [],
  setUploadedFiles: noop,
  uploadedFileData: [],
});

export type AdProviderProps = PropsWithChildren;

export const AdProvider: FC<AdProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  // reset data on mount and unmount
  useEffect(() => {
    const reset = () => {
      dispatch(adActions.resetData());
    };
    reset();
    return reset;
  }, [dispatch]);

  const {
    categoryId,
    dataObjectType,
    platform,
    billingAddressId,
    contactAddressId,
    locationAddressId,
    documents,
    formData,
    propertyFormData,
    images,
    hasChanges,
    categorySearchText,
    paymentProvider,
    print: { paymentInfo, price, selectedIssueIds },
  } = useAppSelector((state) => state.ad);

  // get request params
  const { currentStepKey, dataObjectId, isNew } = useAdParams();
  const { currentStep, currentStepIndex, isLoading: isLoadingSteps, steps } = useAdSteps();
  const { gotoNextStep, gotoPrevStep, gotoStep } = useAdNavigation();

  const validationStatus = useAdValidator();
  const isValid = useMemo(() => validationStatus[currentStepKey]?.isValid, [currentStepKey, validationStatus]);
  const validate = useCallback(() => {
    for (let i = 0; i < steps.length; i++) {
      if (!validationStatus[steps[i].key]?.isValid) {
        gotoStep(steps[i].key);
        toast({
          status: 'error',
          description: validationStatus[steps[i].key]?.message || t('ad.submit.validationError'),
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  }, [gotoStep, steps, t, toast, validationStatus]);

  // fetch data
  const isPrintAdvert = isPrintType(dataObjectType);
  const { data: dataObjectData, isLoading: isLoadingDataObject } = accountApi.useGetDataObjectById(
    { id: dataObjectId! },
    { skip: !dataObjectId || isPrintAdvert }
  );
  const { data: printAdvertData, isLoading: isLoadingPrintAdvert } = accountApi.useGetPrintAdvertById(
    { id: dataObjectId! },
    { skip: !dataObjectId || !isPrintAdvert }
  );
  const dataObject = useMemo(
    () => (isPrintAdvert ? printAdvertData?.dataObject : dataObjectData?.dataObject),
    [dataObjectData?.dataObject, printAdvertData?.dataObject, isPrintAdvert]
  );
  const isLoading = isLoadingDataObject || isLoadingPrintAdvert || isLoadingSteps;

  const [createDataObject, { isLoading: isCreatingDataObject }] = accountApi.useCreateDataObject();
  const [updateDataObject, { isLoading: isUpdatingDataObject }] = accountApi.useUpdateDataObject();
  const [publishDataObject, { isLoading: isPublishing }] = accountApi.usePublishDataObject();
  const [publishPrintAdvert, { isLoading: isPublishingPrint }] = accountApi.usePublishPrintAdvert();
  const isSubmitting = isCreatingDataObject || isUpdatingDataObject || isPublishing || isPublishingPrint;

  // store uploaded files locally only since non-serializable state should not be put into redux
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  // store new files as data URLs to display them before they are actually uploaded
  const [uploadedFileData, setUploadedFileData] = useState<UploadedFileData[]>([]);

  // load data URLs of uploaded files
  useEffect(() => {
    let isMounted = true;
    const loadData = async () => {
      const result: UploadedFileData[] = [];
      for (const img of uploadedFiles.values()) {
        const dataUrl = await readFileAsDataURL(img.file);
        result.push({ fileName: img.file.name, dataUrl });
      }
      if (isMounted) {
        setUploadedFileData(result);
      }
    };
    loadData();
    return () => {
      isMounted = false;
    };
  }, [uploadedFiles]);

  const { attributes } = useAdAttributes();

  // on component mount
  useEffect(() => {
    if (isNew) {
      if (!platform || !dataObjectType) {
        gotoStep(CommonStepKeys.platform, true);
      } else if (!categoryId && currentStepKey !== CommonStepKeys.platform) {
        gotoStep(CommonStepKeys.category, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, dataObjectType, platform]);

  // update validation state
  useEffect(() => {
    if (!isNew) {
      // mark all steps as completed
      dispatch(
        adActions.updateValidationStatus(
          steps.reduce<AdValidationStatus>((completedSteps, adStep) => {
            return {
              ...completedSteps,
              [adStep.key]: {
                isValid: true,
              },
            };
          }, {})
        )
      );
    }
  }, [dispatch, dataObjectId, isNew, steps, validate]);

  // call prepopulate function on data object fetch
  useEffect(() => {
    if (dataObject) {
      dispatch(adActions.setPlatform(dataObject.platform));
      dispatch(adActions.setDataObjectType(dataObject.type));
      dispatch(adActions.setCategoryId(dataObject.categoryId));
      dispatch(adActions.setStatus(dataObject.status));
      dispatch(adActions.setBillingAddressId(dataObject.billingAddressId || undefined));
      dispatch(adActions.setContactAddressId(dataObject.contactAddressId || undefined));
      dispatch(adActions.setLocationAddressId(dataObject.locationAddressId || undefined));

      const formData = dataObject.values.reduce<AdFormData>((values, value) => {
        values[value.attributeId] = {
          value: value.value || undefined,
          entries: value.entries || undefined,
        };
        return values;
      }, {});
      dispatch(adActions.bulkUpdateFormData(formData));

      const propertyData = dataObject.properties.reduce<AdFormData>((values, value) => {
        values[value.key] = {
          value: value.value || undefined,
        };
        return values;
      }, {});
      dispatch(adActions.bulkUpdatePropertyFormData(propertyData));
    } else {
      if (categorySearchText) {
        const titleAttribute = attributes.find((a) => a.key == 'Title');
        if (titleAttribute) {
          dispatch(adActions.updateFormData({ key: titleAttribute.id, value: categorySearchText }));
        }
      }
    }
  }, [attributes, categorySearchText, dataObject, dispatch]);

  /** submits ad data at the last step */
  const { selectedContractId, selectedProductId, selectedExtensionProductIds } = useAdPublishing();

  const saveDataObject = async () => {
    const values = getAttributeFormDataValues(attributes, formData);
    const properties = getPropertyFormDataValues(propertyFormData);

    const appendFiles = (formData: FormData) => {
      uploadedFiles.forEach((file) => {
        formData.append('files', file.file);
      });
    };

    if (isNew) {
      return await createDataObject({
        body: createMultipartRequestBody(appendFiles, {
          categoryId: categoryId!,
          type: dataObjectType!,
          contactAddressId: contactAddressId!,
          billingAddressId: billingAddressId!,
          locationAddressId: locationAddressId!,
          values,
          properties,
          images,
          documents,
        }),
      }).unwrap();
    } else {
      return await updateDataObject({
        id: dataObjectId,
        body: createMultipartRequestBody(appendFiles, {
          id: dataObjectId,
          billingAddressId: billingAddressId!,
          contactAddressId: contactAddressId!,
          locationAddressId: locationAddressId!,
          values,
          properties,
          images,
          documents,
        }),
      }).unwrap();
    }
  };

  const saveAndPublishDataObject = async () => {
    const saveResponse = await saveDataObject();

    const publishResponse = await publishDataObject({
      id: saveResponse.dataObject.id,
      accountPublicationPublishDataObjectRequest: {
        dataObjectId: saveResponse.dataObject.id,
        contractId: selectedContractId,
        productId: selectedProductId,
        extensionProductIds: selectedExtensionProductIds,
        billingAddressId: billingAddressId,
        paymentProvider: paymentProvider === InvoicePaymentProvider ? null : paymentProvider,
      },
    }).unwrap();

    return {
      dataObjectId: saveResponse.dataObject.id,
      orderId: publishResponse.orderId,
      gotoPayment: !publishResponse.publicationId,
    };
  };

  const saveAndPublishPrintAdvert = async () => {
    const saveResponse = await saveDataObject();

    const publishResponse = await publishPrintAdvert({
      accountPrintAdvertPublishPrintAdvertRequest: {
        dataObjectId: saveResponse.dataObject.id,
        printTitleIssueIds: selectedIssueIds,
        paymentProvider: paymentProvider,
      },
    }).unwrap();

    const gotoPayment =
      paymentProvider &&
      paymentProvider !== InvoicePaymentProvider &&
      saveResponse.dataObject &&
      (paymentInfo?.paymentType === 'Immediate' || paymentInfo?.paymentType === 'ImmediateRequired');

    return { dataObjectId: saveResponse.dataObject.id, orderId: publishResponse.orderId, gotoPayment };
  };

  const save = () => {
    return saveDataObject()
      .then(() => {
        toast({ status: 'success', description: t('ad.save.success') });
        navigate(routes.myAds({ status: getFilterStatus(dataObject?.status) }), { replace: true });
      })
      .catch(() => {
        toast({ status: 'error', description: t('ad.save.error'), duration: null, isClosable: true });
      });
  };

  const saveAndPublish = () => {
    if (!validate()) {
      return Promise.resolve();
    }

    const submit = isPrintAdvert ? saveAndPublishPrintAdvert : saveAndPublishDataObject;

    return submit()
      .then((result) => {
        toast({ status: 'success', description: t('ad.submit.success') });
        if (result.orderId && result.gotoPayment) {
          // go to payment step if we have an order ID and no publication ID
          navigate(routes.ad(result.dataObjectId, CommonStepKeys.payment, result.orderId));
        } else {
          navigate(routes.myAds({ status: 'active' }), { replace: true });
        }
      })
      .catch(() => {
        toast({ status: 'error', description: t('ad.submit.error'), duration: null, isClosable: true });
      });
  };

  return (
    <AdContext.Provider
      value={{
        steps,
        validationStatus,
        categoryId,
        formData,
        currentStep,
        currentStepIndex,
        currentStepKey,
        gotoNextStep,
        gotoPrevStep,
        gotoStep,
        save,
        saveAndPublish,
        isNew,
        isValid,
        isLoading,
        isSubmitting,
        hasChanges,
        canSave: !!categoryId && !!dataObjectType,
        dataObject,
        uploadedFiles,
        setUploadedFiles,
        uploadedFileData,
        isPrintAdvert,
        price,
      }}
    >
      {children}
    </AdContext.Provider>
  );
};
